import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "battletd-ui" }
const _hoisted_2 = { class: "top-ui-container" }
const _hoisted_3 = { class: "buttons" }
const _hoisted_4 = { class: "player-gold" }
const _hoisted_5 = { class: "amount" }
const _hoisted_6 = { class: "wave-info" }
const _hoisted_7 = { class: "castle-hp" }
const _hoisted_8 = { class: "shop-container" }
const _hoisted_9 = { class: "bench-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_WaveInfo = _resolveComponent("WaveInfo")!
  const _component_Shop = _resolveComponent("Shop")!
  const _component_Bench = _resolveComponent("Bench")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, { onClick: _ctx.toggleShop }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Shop")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_Button, { onClick: _ctx.spawnWave }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("New Wave")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "coin" }, null, -1)),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.gameState.playerState.gold), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_WaveInfo, {
          waveState: _ctx.gameState.waveState
        }, null, 8, ["waveState"])
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.gameState.playerState?.castle?.hp) + " / " + _toDisplayString(_ctx.gameState.playerState?.castle?.maxHp), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Shop, {
        show: _ctx.showShop,
        onCloseShop: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showShop = false)),
        onToggleShop: _ctx.toggleShop,
        cards: _ctx.gameState.shopState.offerings.map(towerId => _ctx.getTowerCard(towerId)!)
      }, null, 8, ["show", "onToggleShop", "cards"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_Bench, {
        cards: _ctx.gameState.playerState.bench.map(towerId => _ctx.getTowerCard(towerId)!),
        selectedCard: _ctx.gameState.playerState.selectedCard,
        onToggleSelected: _ctx.toggleSelected
      }, null, 8, ["cards", "selectedCard", "onToggleSelected"])
    ])
  ]))
}