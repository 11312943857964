<template>
  <div class="footer">
    <p>© Evan Heaton 2023</p>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.footer {
  margin-top: 20px;
  text-align: center;
}
</style>