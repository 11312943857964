import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tower-image" }
const _hoisted_2 = { class: "cost" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["battletd-tower-card", [`rarity-${_ctx.card!.rarity}`, { selected: _ctx.selected }]]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickTowerCard', _ctx.card!.towerId)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.card!.towerId), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.card!.cost, (i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "coin",
          key: i
        }))
      }), 128))
    ])
  ], 2))
}