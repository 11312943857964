<template>
    <div class="ps-music-page page">
        <h1>Music</h1>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from '../components/Footer.vue';
export default {
  name: 'MusicPage',
  props: {
    
  },
  components: {
    Footer,
  }
}
</script>