import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page battletd-page" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserInterface = _resolveComponent("UserInterface")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "BattleTD", -1)),
    _createElementVNode("div", {
      id: _ctx.containerId,
      class: "game-container"
    }, [
      _createVNode(_component_UserInterface, {
        gameState: (_ctx.gameState as BattleTDGameState)
      }, null, 8, ["gameState"])
    ], 8, _hoisted_2)
  ]))
}