import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "battletd-wave-info" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "wave-number" }
const _hoisted_4 = { class: "bar" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.waveState.waveNumber), 1),
      _createElementVNode("div", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "background" }, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "timeline" }, null, -1)),
        _createElementVNode("div", {
          class: "wave-indicator next",
          style: _normalizeStyle(_ctx.indicatorStyle)
        }, null, 4),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "waveinfo current" }, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "waveinfo next" }, null, -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "waveinfo future" }, null, -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.phaseDescription), 1)
    ])
  ]))
}