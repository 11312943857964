import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "battletd-bench" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TowerCard = _resolveComponent("TowerCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
      return (_openBlock(), _createBlock(_component_TowerCard, {
        key: index,
        card: card,
        selected: _ctx.selectedCard == index,
        onClickTowerCard: ($event: any) => (_ctx.$emit('toggleSelected', index))
      }, null, 8, ["card", "selected", "onClickTowerCard"]))
    }), 128))
  ]))
}