import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "shop-window" }
const _hoisted_2 = { class: "level" }
const _hoisted_3 = { class: "cards-container" }
const _hoisted_4 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_TowerCard = _resolveComponent("TowerCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["battletd-shop", {hide: !_ctx.show}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Lvl. 2", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "bar" }, [
          _createElementVNode("div", { class: "progress" })
        ], -1)),
        _createVNode(_component_Button, null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("+XP")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
          return (_openBlock(), _createBlock(_component_TowerCard, {
            key: index,
            card: card,
            onClickTowerCard: ($event: any) => (_ctx.buyCard(index))
          }, null, 8, ["card", "onClickTowerCard"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeShop')))
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Close")
          ])),
          _: 1
        }),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "spacer" }, null, -1)),
        _createVNode(_component_Button, null, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Lock")
          ])),
          _: 1
        }),
        _createVNode(_component_Button, null, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Refresh")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", {
      class: "toggle",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggleShop')))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["chevron", {left: _ctx.show, right: !_ctx.show}])
      }, null, 2)
    ])
  ], 2))
}